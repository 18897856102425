<template>
  <div ref="hazopDashboard" class="row bg-white">
    <div class="col-2">
      <!-- 사업장 요약정보 -->
      <c-card title="LBL0000969" class="cardClassDetailForm dashborad">
        <template slot="card-detail">
          <div class="col-12">
            <c-plant 
              class="none-bottom" 
              type="none" 
              name="plantCd" 
              v-model="searchParam.plantCd"
              @datachange="plantChange" />
          </div>
        </template>
      </c-card>
      <div class="row">
        <div class="col-12">
          <div class="main-card card riskassess-dashboard">
            <div class="grid-menu grid-menu-3col">
              <div class="no-gutters row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div class="widget-chart bg-warning text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <i class="pe-7s-share text-white"></i>
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="plant.assessCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                      <!-- 평가 진행중 -->
                    <div class="widget-subheading">{{$label('LBL0000970')}}</div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div class="widget-chart bg-danger text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="new_releases"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="plant.riskbookCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <!-- 위험등록부 등재 -->
                    <div class="widget-subheading">{{$label('LBL0000971')}}</div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div class="widget-chart bg-info text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="build"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="plant.improvingCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <!-- 개선 진행중 -->
                    <div class="widget-subheading">{{$label('LBL0000972')}}</div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div class="widget-chart bg-success text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="done"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="plant.imporveCompleteCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <!-- 개선완료 -->
                    <div class="widget-subheading">{{$label('LBL0000973')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 공정 목록 -->
      <c-card title="LBL0000974" class="cardClassDetailForm dashborad q-mt-sm">
        <template slot="card-detail">
          <div class="col-12">
            <el-cascader-panel :options="plant.processes" @change="changeProcess" style="min-height:1050pxpx">
              <template slot-scope="scope">
                <span>{{ scope.data.processName }}</span>
                <span v-if="scope.data.isAssess==='1'">
                  <q-icon name="handyman" color="red-6" class="blinking" />
                </span>
              </template>
            </el-cascader-panel>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="col-12">
          <!-- 안전보건정보 목록 -->
          <c-table
            ref="table"
            title="LBL0000950"
            tableId="safeHealSurvey01"
            :columns="gridSHI.columns"
            :data="gridSHI.data"
            :merge="gridSHI.merge"
            gridHeight="300px"
            :hideBottom="true"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            @linkClick="linkClick"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <!-- 안전보건정보 조사표 -->
                <c-btn v-show="gridSHI.data && gridSHI.data.length > 0" 
                  label="LBL0000951" 
                  icon="assignment"
                  @btnClicked="printChecklist"  />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'sopNames' && props.row.sopNames">
                <q-chip
                  v-for="(item, index) in chipDatas(props.row['sopNames'])"
                  :key="index"
                  outline square
                  color="light-green"
                  text-color="white"
                  style="margin-bottom:4px !important;">
                  {{item}}
                </q-chip>
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-10">
          <c-card :noHeader="true" class="cardClassDetailForm dashborad">
            <template slot="card-detail">
              <div class="col-12">
                <apexchart 
                  ref="chart" 
                  height="260" 
                  type="bar"
                  :width="chart.chartWidth"
                  :options="chart.chartOptions" 
                  :series="chart.series"></apexchart>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-2">
          <div class="main-card card riskassess-dashboard">
            <div class="grid-menu grid-menu-3col">
              <div class="no-gutters row">
                <div class="col-12" @click="noneImprove">
                  <div class="widget-chart bg-deep-orange text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="warning_amber"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="process.noneImprovingCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <!-- 미조치 Punch-list -->
                    <div class="widget-subheading">{{$label('LBL0000975')}}</div>
                  </div>
                </div>
                <div class="col-12" @click="noneRiskbook">
                  <div class="widget-chart bg-danger text-white widget-chart-hover">
                    <div class="icon-wrapper rounded">
                      <div class="icon-wrapper-bg bg-white opacity-2"></div>
                      <q-icon 
                        name="new_releases"
                        coloe='white'
                      />
                    </div>
                    <div class="widget-numbers">
                      <ICountUp :startVal="0" :endVal="process.nonRiskbookCnt" :decimals="0"
                        :duration="4" :options="countOptions" />
                    </div>
                    <!-- 위험등록부 등재 -->
                    <div class="widget-subheading">{{$label('LBL0000971')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <c-tab
            type="tabcard"
            align="left"
            :inlineLabel="true"
            :tabItems="tabItems"
            height="850px"
            v-model="tab"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :searchParam.sync="searchParam"
              />
            </template>
          </c-tab>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import ICountUp from "vue-countup-v2";
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';

export default {
  name: 'process-ram-dashboard',

  components: {
    ICountUp,
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
        processCd: '',
      },
      plant: {
        assessCnt: 0,
        riskbookCnt: 0,
        improvingCnt: 0,
        imporveCompleteCnt: 0,
        processes: [],
      },
      process: {
        nonRiskbookCnt: 0,
        noneImprovingCnt: 0,
        chartData: [],
      },
      gridSHI: {
        merge: [
          { index: 0, colName: 'upProcessCd' },
          { index: 1, colName: 'processCd' },
          { index: 2, colName: 'equipmentCd' },
          { index: 3, colName: 'equipmentCd' },
          { index: 4, colName: 'materialCd' },
          { index: 6, colName: 'materialCd' },
          { index: 7, colName: 'materialCd' },
          { index: 8, colName: 'processCd' },
          { index: 9, colName: 'processCd' },
        ],
        height: '',
        columns: [
          {
            name: 'kras',
            field: 'kras',
            // 공정 평가 대상
            label: 'LBL0000952',
            align: 'center',
            child: [
              {
                name: 'upProcessName',
                field: 'upProcessName',
                label: 'LBLPROCESS',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                type: 'link',
              },
              {
                name: 'processName',
                field: 'processName',
                // 세부공정(작업)
                label: 'LBL0000965',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                type: 'link',
              },
            ]
          },
          {
            name: 'equipment',
            field: 'equipment',
            // 기계기구 및 설비
            label: 'LBL0000964',
            align: 'center',
            child: [
              {
                name: 'equipmentName',
                field: 'equipmentName',
                // 기계기구 및 설비
                label: 'LBL0000964',
                align: 'center',
                style: 'width:200px',
                sortable: false,
                type: 'link',
              },
              {
                name: 'amount',
                field: 'amount',
                label: 'LBLAMOUNT',
                align: 'right',
                type: 'cost',
                style: 'width:70px',
                sortable: false
              },
            ]
          },
          {
            name: 'riskChemprod',
            field: 'riskChemprod',
            // 위험성평가물질
            label: 'LBL0000963',
            align: 'center',
            child: [
              {
                name: 'materialName',
                field: 'materialName',
                // 화학자재
                label: 'LBL0000356',
                align: 'center',
                style: 'width:150px',
                sortable: false,
                type: 'link',
              },
              {
                name: 'chemNmKr',
                field: 'chemNmKr',
                // 화학물질
                label: 'LBL0000962',
                align: 'center',
                style: 'width:200px',
                sortable: false
              },
              {
                name: 'chemDailyVolume',
                field: 'chemDailyVolume',
                // 취급량/일
                label: 'LBL0000961',
                align: 'center',
                style: 'width:100px',
                sortable: false
              },
              {
                name: 'chemDailyHour',
                field: 'chemDailyHour',
                // 취급시간
                label: 'LBL0000960',
                align: 'center',
                style: 'width:100px',
                sortable: false
              },
            ]
          },
          {
            name: 'downPath',
            field: 'downPath',
            // 그 밖의 유해위험정보
            label: 'LBL0000959',
            align: 'center',
            type: 'img',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'job',
            field: 'job',
            // 작업 평가 대상
            label: 'LBL0000958',
            align: 'center',
            child: [
              {
                name: 'sopNames',
                field: 'sopNames',
                label: 'LBLJOB',
                align: 'left',
                style: 'width:200px',
                sortable: false,
                type: 'custom',
              },
            ]
          },
        ],
        data: [],
        allData: [],
      },
      countOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "건"
      },
      chart: {
        chartOptions: {
          title: {
            text: '(최근 5년) Punch-list, 위험등록부'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          }
        },
        series: [{
          name: 'Punch-list',
          data: [0, 0, 0, 0, 0]
          // data: [213, 190, 203, 187, 126]
        }, {
          name: '위험등록부',
          data: [0, 0, 0, 0, 0]
          // data: [80, 76, 85, 69, 42]
        }],
        chartWidth: '80%',
      },
      tab: 'hazop',
      tabItems: [
        { name: 'hazop', icon: 'edit', label: 'HAZOP', component: () => import(`${'./dashHazop.vue'}`) },
        { name: 'kras', icon: 'edit', label: 'KRAS', component: () => import(`${'./dashKras.vue'}`) },
        { name: 'kpsr', icon: 'edit', label: 'K-PSR', component: () => import(`${'./dashKpsr.vue'}`) },
        { name: 'ckechlist', icon: 'edit', label: 'Check-list', component: () => import(`${'./dashChecklist.vue'}`) },
      ],
      editable: true,
      plantInfoUrl: '',
      sheListUrl: '',
      printUrl: '',
      processAssessListUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.plantInfoUrl = selectConfig.ram.dashboard.plantInfo.url
      this.sheListUrl = selectConfig.ram.safeHealSurvey.list.url;
      this.printUrl = selectConfig.ram.safeHealSurvey.print.url
      this.processAssessListUrl = selectConfig.ram.dashboard.processAssess.url
      // code setting
      this.setLangLabel();
      let years = [];
      for (let i = 0; i < 5; i++) {
        years.push(this.$comm.getPrevYear(i));
      }
      this.chart.chartOptions.xaxis.categories = years
      // list setting
      this.$refs['hazopDashboard'].style.setProperty("--cascader-height-var", '1050px');
      this.plantChange();
      this.setChart();
    },
    setLangLabel() {
      let thisVue = this;
      this.chart.series = [
      {
          name: 'Punch-list',
          data: [0, 0, 0, 0, 0]
        },
        {
          name: this.$comm.getLangLabel('LBL0000369'), // 위험등록부
          data: [0, 0, 0, 0, 0]
        },
      ]
      this.chart.chartOptions.title.text = thisVue.$comm.getLangLabel('LBL0000968') // (최근 5년) Punch-list, 위험등록부
      this.chart.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
    },
    plantChange(value) {
      this.$http.url = this.plantInfoUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.extend(this.plant, _result.data)
      },);
    },
    changeProcess(value) {
      // 첫번째 인자는 공정코드, 두번째 인자는 도면id
      this.$set(this.searchParam, 'processCd', value[0])

      // 1. 안전보건정보 조회
      this.$http.url = this.sheListUrl;
      this.$http.param = this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridSHI.data = _result.data;
      },);

      // 2. 공정 위험성평가 정보 조회
      this.$http.url = this.processAssessListUrl;
      this.$http.param = this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.process, _result.data)
        if (this.process.chartData && this.process.chartData.length > 0) {
          this.chart.series[0].data = this.$_.map(this.process.chartData, 'imporveCnt')
          this.chart.series[1].data = this.$_.map(this.process.chartData, 'riskbookCnt')

          this.setChart();
        }
      },);
    },
    setChart() {
      setTimeout(() => {
        this.chart.chartWidth = '100%';
      }, 200);
      this.$refs['chart'].refresh();
    },
    chipDatas(val) {
      return this.$_.split(val, '\n');
    },
    noneImprove() {
      if (this.searchParam.processCd) {
        this.popupOptions.title = 'LBL0000967'; // 해당 공정 미조치 Punch list
        this.popupOptions.param = {
          selectProcessCd: this.searchParam.processCd
        };
        this.popupOptions.target = () => import(`${'./processNonImprove.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeNoneImprovePopup;
      }
    },
    closeNoneImprovePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    noneRiskbook() {
      if (this.searchParam.processCd) {
        this.popupOptions.title = 'LBL0000966'; // 해당 공정 위험등록부 등재
        this.popupOptions.param = {
          selectProcessCd: this.searchParam.processCd
        };
        this.popupOptions.target = () => import(`${'./processNonRiskbook.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeNoneRiskbookPopup;
      }
    },
    closeNoneRiskbookPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClick(row, col, index) {
      if (!row) return;
      if (col.name === 'upProcessName' || col.name === 'processName') {
        this.popupOptions.target = () => import(`${'@/pages/common/process/processInfoPop.vue'}`);
        this.popupOptions.title = 'LBL0000954';
        this.popupOptions.param = {
          processType: col.name === 'upProcessName' ? 'P' : 'C', // P: 상위공정, C: 단위공정
          processCd: col.name === 'upProcessName' ? row.upProcessCd : row.processCd, // 공정코드
        };
        this.popupOptions.width = '60%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name === 'equipmentName') {
        if (row.equipType === 'equipment') {
          this.popupOptions.title = 'LBL0000955';
          this.popupOptions.param = {
            equipmentCd: this.$_.split(row.equipmentCd, '/')[1],
            psmFlag: row ? row.psmFlag : '',
          };
          this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
          this.popupOptions.visible = true;
          this.popupOptions.width = '90%';
          this.popupOptions.closeCallback = this.closePopup;
        } else {
          this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
          this.popupOptions.param = {
            hhmHazardousMachineryId: row.hhmHazardousMachineryId
          };
          this.popupOptions.target = () =>
            import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
          this.popupOptions.visible = true;
          this.popupOptions.width = '90%';
          this.popupOptions.closeCallback = this.closePopup;
        }
      } else if (col.name === 'materialName') {
        this.popupOptions.title = 'LBL0000957'; // 화학자재 상세
        this.popupOptions.param = {
          mdmChemMaterialId: row.mdmChemMaterialId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    printChecklist() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = '안전보건조사표_' + this.$comm.getToday() + '.docx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important

// .el-cascader-node
//   padding: 0 30px 0 5px !important
// .el-cascader-menu
//   min-width: auto
// .el-cascader-node__label
//   padding: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  // border-right: #dee2e6 solid 0 !important
  // border-bottom: #dee2e6 solid 1px !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>